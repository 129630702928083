import { createI18n, type I18nOptions } from 'vue-i18n'

/**
 * import locale messages resource from json for global scope
 */
import enGB from './locales/en-GB.json'
import etEE from './locales/et-EE.json'
import ruRu from './locales/ru-RU.json'

const options: I18nOptions = {
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    'en': enGB,
    'et': etEE,
    'ru': ruRu
  },
  // datetimeFormats: {
  //   'ja-JP': {
  //     short: {
  //       hour: 'numeric',
  //       minute: 'numeric',
  //       second: 'numeric',
  //       timeZoneName: 'short',
  //       timezone: 'Asia/Tokyo'
  //     }
  //   }
  // },
  // numberFormats: {
  //   'ja-JP': {
  //     currency: {
  //       style: 'currency',
  //       currencyDisplay: 'symbol',
  //       currency: 'JPY'
  //     }
  //   }
  // }
}

/**
 * setup vue-i18n with i18n resources with global type definition.
 * if you define the i18n resource schema in your `*.d.ts`, these is checked with typeScript.
 */
const i18n = createI18n<false, typeof options>(options)

export default i18n
